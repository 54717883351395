
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































































































































































































.slider-products {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  // overflow: hidden;
  position: relative;

  // TODO
  .slider-products {
    margin-bottom: 0;
  }
}

.slider-products-wrapper {
  @include mq($until: m) {
    padding-left: col(3, 24);
    padding-right: 0;
  }
}

.slider-products__slider {
  position: relative;
  // overflow: hidden;
}

.slider-products__slider__list {
  @extend %list-nostyle;

  display: flex;
  margin-right: -1rem;
  margin-left: -1rem;

  @include mq(m) {
    margin-right: -2.5vw;
    margin-left: -2.5vw;
  }
}

.slider-products__title {
  text-align: center;

  ::v-deep {
    b,
    strong {
      @extend %fw-light;

      color: $c-yellow;
    }
  }
}

.slider-products-outer {
  overflow: hidden;
}

.slider-products__item {
  flex: 0 0 col(18, 21);
  padding: 0 1rem;

  @include mq(m) {
    flex: 0 0 calc(100% / 3);
    padding: 0 2.5vw;
  }
}

.slider-products__card {
  width: 100%;
  height: 100%;
}

.slider-products__slider__wrapper {
  overflow: hidden;
}

.slider-products__slider__nav {
  @include mq($until: m) {
    position: absolute;
    top: 50%;
    width: col(18, 21);
    height: 5rem;
  }
}

.slider-products__slider__nav__prev {
  left: 0;
  transform: translate(-50%, -50%);

  ::v-deep {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.slider-products__slider__nav__next {
  transform: translate(50%, -50%);
  right: 0;
}

.slider-products__slider__nav__prev,
.slider-products__slider__nav__next {
  @extend %shadow;

  position: absolute;
  z-index: 2;
  top: 50%;
  width: 5rem;
  height: 5rem;
  background-color: $c-white;
  border: 0;
  border-radius: 1.2rem;
  transition: background-color 0.5s $ease-out-quad;

  ::v-deep {
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &:hover {
    background-color: $c-yellow;
  }
}
