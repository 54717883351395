
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































































































































































.filters__header {
  margin-bottom: 6rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $c-black-40;
}

.filters__btn {
  @extend %ff-alt;
  @extend %fw-normal;

  margin-left: col(1, 24);
  display: inline-flex;
  align-items: center;
  color: $c-black;
  font-size: 1.3rem;
  line-height: 4.6rem;
  letter-spacing: 0.5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.filters__btn__count {
  @extend %shadow;
  @extend %tag;

  margin-left: 1rem;
}

.filters__modal {
  @include get-all-space;

  position: fixed;
  z-index: 20;
  top: 0;
  overflow-y: scroll;
  width: 100%;
}

.filters__overlay {
  @include get-all-space;

  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  background: rgba($c-black, 0.5);
  cursor: pointer;
}

.filters__form {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  padding-bottom: 3rem;
  width: 100%;
  min-height: 100%;
  background: $c-white;
  animation: slideM 0.6s $custom-animation;

  > div {
    padding-inline: col(2, 24);
    @include mq(m) {
      padding-inline: col(1, 24);
    }
  }

  @include mq(m) {
    @include center-x;

    top: 11rem;
    max-width: col(16, 24);
    min-height: auto;
    height: auto;
    border-radius: 24px;
    animation: slide 0.6s $custom-animation;
  }
}

@keyframes slideM {
  0% {
    transform: translate(0, -6rem);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slide {
  0% {
    transform: translateY(-50%, -6rem);
    opacity: 0;
  }

  100% {
    transform: translateY(-50%, 0);
    opacity: 1;
  }
}

.filters__form__header {
  @extend %fw-normal;

  position: relative;
  margin-bottom: 6rem;
  padding-top: 2.9rem;
  padding-bottom: 2.5rem;

  font-style: normal;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid $c-black-40;
}

.filters__form__title {
  @extend %ff-alt;

  color: $c-yellow;
}

.filters__form__close {
  position: absolute;
  top: 50%;
  right: col(1, 24);
  padding: 1rem;
  line-height: 1;
  cursor: pointer;
  transform: translate(-50%, -50%);

  @include mq(m) {
    right: col(1, 24);
  }
}

.filters__form__section {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid $c-black-40;
}

.filters__form__section__title {
  margin-bottom: 3rem;
}

.filters__form__section__checkboxes {
  @include mq(l) {
    display: flex;
    flex-wrap: wrap;

    > .form-group {
      width: 50%;
    }
  }
}

.filters__form__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 5.8rem;
}

.filters__form__cancel {
  @extend %fw-light;

  font-size: 1.3rem;
  border-bottom: 1px solid $c-black;
  cursor: pointer;
}

// CHECK
.form-group {
  position: relative;
  margin-bottom: 2.4rem;
}

.form-checkbox {
  @extend %visually-hidden;
}

.form-label {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 4.7rem;
  color: $c-black;
  font-size: 1.4rem;
  line-height: 2.6rem;
  cursor: pointer;
  letter-spacing: 0.5px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    border: 1px solid $c-black-60;
    border-radius: 0.4rem;
    transition: border-color 0.2s;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0.3rem;
    display: inline-block;
    width: 1.8rem;
    height: 1.8rem;
    background-color: $c-black-60;
    border-radius: 0.4rem;
    transition: opacity 0.2s;
    transform: translateY(-50%);
    opacity: 0;
  }

  .form-checkbox:checked + & {
    &::after {
      opacity: 1;
    }
  }

  .form-checkbox:focus + & {
    &::before {
      border: 1px solid $c-black;
    }
  }
}
