
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.products-list {
  @include fluid(
    margin-bottom,
    (
      s: 80px,
      xl: 120px,
    )
  );

  position: relative;
  margin-top: 2rem;

  ::v-deep {
    [class*='action--'] {
      @include mq(m) {
        background-color: $c-white;
      }
    }
  }
}

.products-list + .products-list {
  @include mq($until: m) {
    padding-top: 2.4rem;
    padding-bottom: 0;
    border-top: 1px solid $c-black-40;
  }
}

.products-list__item {
  @extend %shadow;

  position: relative;
  display: flex;
  align-items: center;

  @include mq($until: m) {
    align-items: flex-start;
    box-shadow: none;

    & + & {
      padding-top: 2.4rem;
      padding-bottom: 0;
      border-top: 1px solid $c-black-40;
    }
  }

  @include mq(m) {
    padding: 3rem col(1, 24);
    border-radius: 16px;
  }
}

.products-list__item + .products-list__item {
  margin-top: 2.4rem;

  @include mq(m) {
    margin-top: 2rem;
  }
}

.product__label-container {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    padding-top: 0.4rem;
  }

  @include mq(m) {
    align-items: center;
    flex: 1;
  }
}

.product__isotope {
  min-width: 8rem;

  @include mq(m) {
    width: col(2, 22);
  }

  div {
    @extend %tag;
    @extend %text-nodecoration;

    display: inline-flex;
    margin-right: 2rem;
    color: $c-white;
    background-color: $c-blue;
    border-color: $c-blue;

    @include mq(m) {
      margin-right: 0;
    }
  }
}

.product__label {
  @extend %text-nodecoration;
  @include fluid(
    font-size,
    (
      s: 14px,
      xl: 16px,
    )
  );

  color: $c-dark-blue;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }

  @include mq(m) {
    padding-right: 1.5rem;
    width: col(8, 20);
  }
}

.product__target {
  @extend %text-nodecoration;

  display: block;
  color: $c-black-80;
  font-size: 1.4rem;

  @include mq($until: m) {
    margin-bottom: 0.8rem;
  }
}

.product__synthetizers {
  @extend %ff-alt;
  @extend %list-nostyle;

  color: $c-black-60;
  font-size: 1.3rem;

  span + span {
    &::before {
      content: '/';
      margin-left: 0.6rem;
      padding-left: 0.6rem;
    }
  }

  @include mq(m) {
    margin-left: auto;
  }
}

.product__link {
  @include get-all-space;
}
