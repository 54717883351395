
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.anchors {
  @extend %shadow;

  transition: all 0.5s $ease-out-quad;

  @include mq($until: m) {
    background: $c-white;
  }

  @include mq(m) {
    box-shadow: none;
  }

  &.is-sticky {
    position: fixed;
    top: 7rem;
    left: 0;
    z-index: 10;
    width: 100%;

    @include mq(m) {
      top: 14rem;
    }
  }
}

.anchors-wrapper {
  @include mq($until: m) {
    padding: 0 2rem;
  }
}

.anchors__list {
  @extend %list-nostyle;
  @extend %shadow;

  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: $c-white;

  @include mq($until: m) {
    overflow-x: scroll;
    box-shadow: none;
  }

  @include mq(m) {
    border-radius: 1rem;
  }
}

.anchors__items {
  a {
    padding: 2.1rem 2.2rem 1.9rem;
    display: block;
    border-bottom: 3px solid transparent;
    opacity: 0.5;
    text-decoration: none;
    white-space: nowrap;

    @include mq(m) {
      padding: 2.1rem 4.2rem 1.9rem;
    }
  }

  span {
    @extend %fw-normal;

    color: $c-black;
    font-size: 1.4rem;
    line-height: 26px;
    letter-spacing: 0.5px;
  }

  &:hover,
  &.is-active {
    a {
      border-bottom: 3px solid $c-yellow;
      opacity: 1;
    }
  }
}
