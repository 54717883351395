
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































.toast {
  @extend %shadow;

  position: absolute;
  top: 12rem;
  left: 50%;
  z-index: 9;
  transform: translateX(-50%);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem 1.5rem 3rem;
  max-width: 50rem;
  background-color: $c-white;
  border-radius: 2rem;

  @include mq($until: m) {
    top: 7rem;
    width: 80%;
  }
}

.toast__text {
  @extend %text-nodecoration;

  color: $c-content;
  font-size: 1.4rem;
  line-height: 2.6rem;
  letter-spacing: 0.5px;

  span {
    text-decoration: none;
    background: no-repeat 0 100%;
    background-image: linear-gradient($c-black-50, $c-black-50);
    background-size: 100% 1px;
  }
}

.toast__close {
  margin-left: 3rem;
  padding: 1rem;

  cursor: pointer;
}
