
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































































































.products-category {
  ::v-deep {
    .cards-grid {
      @include fluid(
        margin-bottom,
        (
          s: 40px,
          xl: 100px,
        )
      );
    }
    // .cards-grid-inner > div {
    //   margin-bottom: 0;
    // }

    .load-more [class*='action--'] {
      background-color: $c-dark-blue;

      .action__label {
        color: $c-white;
      }

      svg {
        path {
          fill: $c-white;
        }
      }
    }
  }
}

.products__hero {
  @include fluid(
    padding-bottom,
    (
      s: 36px,
      xl: 68px,
    )
  );
}
.products__cards-grid {
  margin-top: 0;
}

.products-list + .products-list {
  @include fluid(
    margin-top,
    (
      s: -60px,
      xl: -100px,
    )
  );
}

.products-list + .load-more {
  @include fluid(
    margin-top,
    (
      s: -20px,
      xl: -60px,
    )
  );
}

.load-more {
  @include fluid(
    margin-bottom,
    (
      s: 40px,
      xl: 80px,
    )
  );
  display: flex;
  justify-content: center;
}

// Transition

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
