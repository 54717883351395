
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































.section {
  hr {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $c-black-40;
  }
}
.section-wrapper {
  @include fluid(
    margin-bottom,
    (
      s: 60px,
      xl: 100px,
    )
  );
}
.section__title {
  @include fluid(
    margin-bottom,
    (
      s: 30px,
      xl: 60px,
    )
  );

  color: $c-dark-blue;
  text-align: center;
}

.section__btn-outer {
  @include fluid(
    padding-bottom,
    (
      s: 40px,
      xl: 58px,
    )
  );

  display: flex;
  justify-content: center;
}
