
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































.products {
  ::v-deep {
    .products__section {
      &:last-child {
        .section__btn-outer {
          padding-bottom: 0;
        }
        hr {
          display: none;
        }
      }
    }

    // .cards-grid-inner > div {
    //   margin-bottom: 0;
    // }
  }
}

.products__hero {
  background-color: $c-black-20;

  @include mq(m) {
    &:before {
      content: '';
      width: 100%;
      height: 18rem;
      background-color: $c-black-20;

      position: absolute;
      top: -18rem;
      left: 0;
    }
  }
}

.products__anchors {
  @include mq(m) {
    transform: translateY(-50%);
  }
}

.products__section-wrapper {
  margin-top: 3rem;

  @include mq(m) {
    margin-top: 6.2rem;
  }
}

.products__duo {
  @include mq($until: m) {
    margin-bottom: 0;
  }
}
